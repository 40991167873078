import { get, getSSR, getSwr, post, postSwr } from "@common/network/networkInterface"
import { getFullUrl, handleError, postFetcher } from "@common/network/utils"

export function updateAccountDetails(details) {
  return post(`update_account_details`, details)
}

export function getAccountDetails(shouldFetch) {
  return getSwr("get_account_details", shouldFetch)
}

export function inviteUsers(details) {
  const apiUrl = "invite_users"
  return post(apiUrl, details)
}
export function getTeamAccounts() {
  return getSwr("get_team_accounts")
}

export function getTeams(shouldFetch) {
  return getSwr("get_teams", shouldFetch)
}

export function updateTeam(details) {
  const apiUrl = "update_team"
  return post(apiUrl, details)
}

export function updateUserResource(details) {
  const apiUrl = `resource/update_user`
  return post(apiUrl, details)
}

export function deleteUser(account_id) {
  const apiUrl = "resource/delete_user"
  return post(apiUrl, { account_id })
}
export function deleteTeam(teamId) {
  const apiUrl = `teams/delete_team/${teamId}`
  return get(apiUrl)
}
// account login/signup/isLoggedIn
export const logout = async () => {
  const apiUrl = "logout"
  const url = getFullUrl(apiUrl)
  try {
    const response = await fetch(url)
    const resCode = response.status
    const success = resCode == 200 ? true : false
    if (resCode == 200)
      return {
        success: success,
        isError: null,
      }
  } catch (error) {
    return {
      success: false,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const getLogin = async (details) => {
  return post(`login`, details)
}

export const getQuickLogin = async (details) => {
  return post(`login/magic_link`, details)
}

//? ******* DO NOT USE GET, FETCHER HERE ,THIS IS INVOKED IN SERVER SIDE SO WE DON'T WANT TO REDIRECT *******
export const getIsLoggedIn = async (cookie, query) => {
  const apiUrl = `is_logged_in`
  let url = getFullUrl(apiUrl)
  if (query) url += `?${query}`
  try {
    const response = await fetch(url, cookie)
    const data = await response.json()
    const resCode = response.status
    return {
      data: data,
      resCode: resCode,
      isError: null,
    }
  } catch (error) {
    return {
      data: null,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const useIsLoggedIn = (publicId) => {
  const apiUrl = `is_logged_in?public_id=${publicId}`
  return getSwr(apiUrl)
}

export const getSignup = async (details) => {
  return post(`register`, details)
}

export const getResetPassword = async (details) => {
  return post(`update_password`, details)
}

export const getResetPasswordToken = async (details) => {
  return post(`reset_pass_send_token`, details)
}

export async function gLogin(details) {
  return post(`google_oauth_redirect`, details)
}

export function resendOtp() {
  return get(`resend_otp`)
}

export function verifyOtp(details) {
  return post(`verify_otp`, details)
}

export function getQuota() {
  const shouldFetch = true
  const { data, isLoading, isError, message, status } = getSwr(
    `get_quota`,
    shouldFetch
  )
  return {
    quota: data,
    isQuotaLoading: isLoading,
    isQuotaError: isError,
    message,
    status,
  }
}

// payments
export function getPaymentPlans(details, shouldFetch = true) {
  return postSwr(`get_plans_meta`, details, shouldFetch)
}

// account preferences
export const getAccountPreferences = (
  customerId,
  shareableUrlId,
  shouldFetch = true
) => {
  const fetch = (!!customerId || !!shareableUrlId) && shouldFetch == true
  if (!fetch) return Promise.reject({ error: "Customer not found" })
  let apiUrl = `get_account_prefs/${customerId || shareableUrlId}`
  apiUrl = shareableUrlId ? apiUrl + `?shareable_url_id=${shareableUrlId}` : apiUrl
  return get(apiUrl)
}

// customer preferences
export const getCustomerPreferences = (customerId, shareableUrlId) => {
  let apiUrl = `get_customer_prefs/${customerId ?? shareableUrlId}`
  apiUrl = shareableUrlId ? apiUrl + `?shareable_url_id=${shareableUrlId}` : apiUrl
  return get(apiUrl)
}

export const updateCustomerPreferences = async (customerId, details) => {
  return await post(`update_customer_prefs/${customerId}`, details)
}

export const updateAccountPreferences = async (customerId, details) => {
  return await post(`update_account_prefs/${customerId}`, details)
}

// TODO - support shareableUrlId before using
export function getAccountPreferencesSSR(cookie, customerId) {
  const apiUrl = `get_account_prefs/${customerId}`
  return getSSR(cookie, apiUrl)
}

export async function uploadFile(url, formData) {
  const fullUrl = getFullUrl(url)
  try {
    return await postFetcher(fullUrl, formData, {})
  } catch (error) {
    return handleError(error, `uploadFile ${url}`)
  }
}
