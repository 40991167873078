import { isArray, isNullOrUndefined, isObjectsEqual } from "./util"

export const ErrorCodes = Object.freeze({
  MULTI: "Please correct the errors in the form.",
  GENERAL: "Something went wrong. Please try again.",
  GENERAL_ACKNOWLEDGED:
    "Something went wrong. We've made a note of this and will be fixing this asap!",
  EMPTY: "Please complete all required fields.",
  EMPTY_LOGIN: "Please enter both email and password.",
  INCORRECT_EMAIL_PWD: "Please enter correct email id and password.",
  DUPLICATE_ACCOUNT: "An account already exists with this email.",
  "type_error.integer": "One of the fields expects a numeric value.",
})

/*
export function isFormCompleted(form) {
  let temp = form
  let isFormComplete = true
  for (let key in temp) {
    let details = temp[key]
    if ("errorMessage" in details && details["errorMessage"] != undefined && details["errorMessage"].length > 0)
      isFormComplete = false
  }
  return isFormComplete
}
*/

/**
 *
 * @param {Record<string,any>} form -> FormConfigs with updated form states.
 * @returns {Record<string,any>} -> Form values
 */
export function getDetailsFromForm(form) {
  let details = {}
  getDetailFromConfig(form)
  function getDetailFromConfig(form, parentKey, parentValue) {
    for (const key in form) {
      if (form[key] && form[key].field != "info") {
        const isChild = parentKey != null
        const shouldAddField =
          !isChild || (form[key].rules && form[key].rules.value === parentValue)
        if (form[key].children) {
          const { value } = form[key]
          if (shouldAddField && !isNullOrUndefined(value))
            details = { ...details, [key]: value }
          getDetailFromConfig(form[key].children, key, value)
        } else if (key == "advanced") {
          getDetailFromConfig(form[key])
        } else if (form[key].field === "group") {
          let { minCount = 1, fields = {}, value } = form[key] || {}
          if (!value) {
            const object = {}
            for (const key in fields) {
              if (Object.hasOwnProperty.call(fields, key)) {
                const element = fields[key]
                object[key] = element.value
              }
            }
            value = Array(minCount).fill(object)
          } else if (isArray(value)) value = value.slice(0, minCount)

          details = { ...details, [key]: value }
        } else {
          if (shouldAddField && !isNullOrUndefined(form[key]))
            details = { ...details, [key]: form[key].value }
        }
      }
    }
  }
  return details
}

export function isFormCompleted(formConfig, formValues, enforceEdit) {
  let isFormCompleted = true

  function checkFormValue(details, fieldValue, parentFieldValue) {
    const {
      field,
      required,
      max,
      min,
      maxLength,
      minLength,
      type,
      rules,
      children,
      minCount,
      fields,
    } = details || {}

    if (required) {
      if (parentFieldValue && rules && parentFieldValue != rules["value"]) {
        return
      }
      if (
        field === "multi_input" ||
        field === "combobox" ||
        field === "multiSelect"
      ) {
        if (!fieldValue || fieldValue.length === 0) {
          isFormCompleted = false
          return
        }
      } else if (fieldValue == null || fieldValue === "") {
        isFormCompleted = false
        return
      }

      if (type === "email" && !fieldValue.includes("@")) {
        isFormCompleted = false
        return
      }

      if (max && Number(fieldValue) > max) {
        isFormCompleted = false
        return
      }

      if (min && Number(fieldValue) < min) {
        isFormCompleted = false
        return
      }

      if (maxLength && fieldValue.length > maxLength) {
        isFormCompleted = false
        return
      }

      if (minLength && fieldValue.length < minLength) {
        isFormCompleted = false
        return
      }
    }

    if (field === "group" && minCount) {
      if (!Array.isArray(fieldValue) || fieldValue.length < minCount) {
        isFormCompleted = false
        return
      }

      for (const item of fieldValue) {
        for (const key in item) {
          checkFormValue(fields[key], item[key])
        }
      }
    }

    if (children) {
      for (const key in children) {
        checkFormValue(children[key], fieldValue)
      }
    }
  }

  for (const key in formConfig) {
    if (formConfig[key]) checkFormValue(formConfig[key], formValues[key])
  }

  if (enforceEdit) {
    //⚠️ COMPARES THE EXISTING CONFIG VALUES WITH THE LATEST ONE TO DETECT "EDIT"
    const originalForm = getDetailsFromForm(formConfig)
    isFormCompleted = !isObjectsEqual(originalForm, formValues) && isFormCompleted
  }
  return isFormCompleted
}

export function resetForm(form) {
  const temp = {}
  for (const key in form) {
    const details = form[key]
    details.value = ""
    temp[key] = details
  }
  return temp
}
